<template>
    <div class="body-cont">
		<div class="div-container app-header">
			<div class="div-row">
				<div class="div-col12">
          <NavTopbar :user="user"/>
				</div>
			</div>
		</div>
		    <div class="app-body-container cratstrbody-content padlr15">
			    <div class="app-body-row">
				    <div class="app-body-col mainprof-sec">
						  <div class="input-group">
                  <input type="text" class="form-control input-form" placeholder="Heading" v-model="user.place_heading" @change="updatePlaceStreamingInfo($event)">
              </div>
            </div>
            <div class="app-body-col mainprof-sec mart15">
            	<div class="input-group">
                <input type="text" class="form-control input-form" placeholder="Description" v-model="user.place_description" @change="updatePlaceStreamingInfo($event)">
              </div>
            </div>
            <div class="cover-photosec"  @click="imageClickHandler()"  :style="{'background-image': 'url(' + user.place_avatar_full_path + ')','background-repeat': 'no-repeat','background-position': 'center center' }">
            	<div class="cover-imgsec" :style="{'background-image': 'url(' + user.place_avatar_full_path + ')','background-repeat': 'no-repeat','background-position': 'center center' }">
                            <input hidden type="file" ref="file-uploader" @change="onFileChange"/>
              </div>
            </div>
            <p class="goliv-txt">Choose when go to live</p>
              <div class="input-group mainprof-input">
                <input type="datetime-local" v-model="user.datetimes"  @change="updatePlaceStreamingInfo($event)" class="form-control input-form datetimeberg" placeholder="Date & Time">
              </div>
				</div>
			</div>


	<div class="app-bottom-container app-bottom">
			<div class="app-bottom-row">
				<div class="app-bottom-col">

					<table class="mainprof-botbar-table">
						<tbody>
							<tr>
								<td>
                  <a href="#" v-if="user.geolocation_enable === 'No'"   @click=callGeolocation(user.geolocation_enable)   class="bot-icon"><img class="strambt-icon" src="/images/icon/map-icon.png"></a>
									<a href="#" v-else-if="user.geolocation_enable === 'Yes'"   @click=callGeolocation(user.geolocation_enable) class="bot-icon"><img class="strambt-icon" src="/images/icon/map-icon-active.png"></a>
								</td>
								<td>
									<a  style="display:none" href="#" v-if="user.is_streaming_public === 'No'"   @click=isStreamingPublicactive(user.is_streaming_public) class="bot-icon"><img class="strambt-icon" src="/images/icon/post-icon.png"></a>
                  <a   style="display:none" href="#" v-if="user.is_streaming_public === 'Yes'"   @click=isStreamingPublicactive(user.is_streaming_public) class="bot-icon"><img class="strambt-icon" src="/images/icon/post-icon-active.png"></a>
									<button class="bot-livbtn" style="display: none;"><img  style="margin-left:10px" class="strambt-icon" @click="updateCurrentNavItem('dashboard')" src="/images/icon/post_button.png"></button>
                  <table class="postliv-table">
										<tbody>
											<tr>
												<td><button class="strm-btn post-btn"><img @click="updateCurrentNavItem('dashboard')" src="ressources/netpost_button1.png"></button></td>
												<td><button class="strm-btn live-btn"><img @click="updateCurrentNavItem('live-streaming/'+user.id)" src="ressources/netlive_button1.png"></button></td>
											</tr>
										</tbody>
									</table>

								</td>

								<td @click="toggelShareing">
									<p class="bot-icon"><img class="strambt-icon" src="/images/icon/share-icon.png">
                    <div class="socialicon-list" id="">
				            <ul class="socialicon-listul" style="list-style: none;" v-show="upHere">
				                <li><a :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`" target="_blank"><i class="fa-solid fa-message"></i></a></li>
				                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}/live-streaming/${user.id}`" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
				                <li><a :href="`https://api.whatsapp.com/send?text=https://preproduction.net.com.au  data-action='share/whatsapp/share'`"><i class="fa-brands fa-square-whatsapp fa-beat"></i></a></li>
				                <li><a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}/live-streaming/${user.id}`" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
				                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
				            </ul>
				            </div>
                  </p>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
			</div>
	</div>
</div>

</template>
<script>
import api from "@/api.js";
import _ from "lodash";

import { mapState } from "vuex";
import NavTopbar from "@/components/NavTopbar";

export default {
  props: ["user"],
  components: {
    'NavTopbar':NavTopbar
  },
  data() {
    return {
      showAddressBook:false,
      showNotifications:false,
        liveStreamingItem: {
        name: "liveStreaming",
        icon: "",
        disabled: false,
        selected: false
      },
      stopLiveStreamingItem: {
        name: "stopLiveStreaming"
      },
      active:false,
      imageUrls: {
        mingle: {
          default: "/images/icon/mingle",
          active: "/images/icon/mingle_active.png"
        },
        place: {
          default: "/images/icon/place",
          active: "/images/icon/place_active.png"
        },
        product: {
          default: "/images/icon/product",
          active: "/images/icon/product_active.png"
        }
      },
      pageUrl:location.hostname,
      dialog: false,
      showingPlayback: false,
      upHere:false,
      map: null,
      infoWindow: null,
      videoStreamingUsers: [],
      peers: {},
      stream: null,
      isCameraEnabled: false,
      clickedUserId: null,
      isViewer: false,
      isStreamer: false,
      showMap: false,
      showVideo: false,
      showCategory: false,
      viewers: [],
      streamingUser: null,
      isLoading: {
        availableUsers: false,
        avatar: false,
        user: false
      },
      placeStreamingInfo: {
        place_heading: "",
        place_description: ""
      },
      showSnackbar: false,
      showStreamingUsersList: false,
      showSearchBar: false,
      isFrontCamera: true,
      otherUserId: null,
      selectedItem: null,
      datetime: '2018-05-12T17:19:06.151Z',

    };
  },
  computed:{
    items() {
            const items = [
                { name: "userMenu", text: this.user.name, icon: "fa-solid fa-user" },
                {
                    name: "notifications",
                    text: "Notifications",
                    icon: "fa-solid fa-bell"
                },
                { name: "contacts", text: "Contacts", icon: "fa-regular fa-address-book" },
               /* {
                    name: "people",
                    text: "People",
                    icon: "fa-solid fa-users"
                }, */

                //{ name: "settings", text: "Settings", icon: "fa-solid fa-gear" },
                { name: "logout", text: "Logout", icon: "fa-sharp fa-solid fa-right-from-bracket" }
            ];
            return items;
        },
    },
  mounted() {
    setTimeout(() => {
     // this.$refs.thumbnailVideo.src = this.user.thumbnail_video_full_path;
     // this.$refs.thumbnailVideo[1].play();
      this.detectLocation = !!this.user.lat;

    }, 3000);

  },
  watch: {
    user: {
      handler(value) {
        if (!value.id) {
          return;
        }
        //this.setupPusher();
      },
      deep: true
    },

    $route(route) {
      this.getUsersBykeyword(route.query);
    },

  },
  methods: {

    async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = true;
            this.active=false;
            await api.notification.markAsRead();
           // this.getUser();
    },

    CloseNotification(){
      this.showNotifications= false;
      this.active=true;
    },
    toggleAddressBooksclose(){
      this.showAddressBook= false;
      this.active=true;
    },

    async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = true;
            this.active= false;
    },
    GenerateURL(){
    var fullUrl = window.location.origin + this.$route.path;
    },
    async isStreamingPublicactive(value){
      var isstreaming=value;
      if(value=="Yes"){
        isstreaming='No';
      }else{
        isstreaming="Yes";
      }
      const datavalue = {
        is_streaming_public: isstreaming
      };
      await api.user.update(this.user.id, datavalue);
      const res = await api.auth.user(this.user.id);
      this.user.is_streaming_public= res.data.is_streaming_public;
      this.updateUserInfo(datavalue);

    },
    async updateUserInfo(data) {
      await api.user.update(this.user.id, data);
    },
    async callGeolocation(value){
      var gstatus=value;
      if(value=="Yes"){
        gstatus='No';
      }else{
        gstatus="Yes";
      }
      const res= await api.user.update(this.user.id, {lat: "",lng: "",geolocation_enable:gstatus});
      this.user.geolocation_enable=res.data.geolocation_enable;
      //this.user.geolocation_enable=gstatus;

             // alert(JSON.stringify(navigator));

      if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
             // alert(position.coords.latitude);
             // alert(JSON.stringify(position.coords));

           const res=this.updateUserInfo({
              lat: position ? position.coords.latitude : "",
              lng: position ? position.coords.longitude : "",
              //geolocation_enable:"Yes"

            });
           // this.user.geolocation_enable= res.data.geolocation_enable;

          },this.getError,{enableHighAccuracy: true, maximumAge: 10000});
        } else {
          const res=this.updateUserInfo({
          lat: "",
          lng: "",
          geolocation_enable:"No"
        });
        //this.user.geolocation_enable= res.data.geolocation_enable;
        alert('Geo Location feature is not supported in this browser.');

        }
        this.getLocation();
      this.detectLocation=true;


    },
    getLocation() {
        navigator.geolocation.getCurrentPosition(this.callback, this.getError,{enableHighAccuracy: true, maximumAge: 10000});
    },
    callback (position) {
           const  currentlat = position.coords.latitude;
           const currentlng = position.coords.longitude;
           const res=this.updateUserInfo({
              lat: currentlat,
              lng: currentlng,
              //geolocation_enable:"Yes"

            });
            console.log("latitude: " + currentlat+"\nlongitude: "+ currentlng);
            alert("latitude: " + currentlat+"\nlongitude: "+ currentlng);

    },

    getError(err){
           // console.log(err);
            alert(err.message);
    },

    updateCurrentNavItem(item) {
        location.href = "/"+item;
    },

    menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                /*case "people":
                    this.goToHome();
                    break;
                    */
               /* case "randomChat":
                    location.href = "/video-chat";
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                    */
                case "logout":
                    this.logout();
                    break;
            }
    },
    mouseOverFun(){
            if(this.active){
            this.active =false;
            }else{
              this.active=true;
            }
    },
    toggelShareing(){
      this.upHere=!this.upHere;
    },
    mouseOverout: function(){
            this.active =false;
    },
    goToHome() {
      location.href = "/";
    },
    goDashboard(){
      location.href = "/dashboard";
    },

    logoSrc() {
            return "/images/logo/net-app-log-white.png";
    },
    logoVideoSrc() {
            return "/images/logo/video-camera-white.png";
    },
    goToNewStreamingPage() {
            location.href = "/new-streaming";
    },
    updateUriByKeyword: _.debounce(function(keyword = "") {
      const query = { heading: keyword };
      this.$router.push({
        query
      });
    }, 700),
    async getUsersBykeyword(query) {
      this.isLoading.user = true;
      const res = await api.user.getVideoStreamingUsers({
        params: {
          category: "place",
          heading: query.heading
        }
      });
      this.videoStreamingUsers = res.data;
      this.isLoading.user = false;
    },
    routeTo(route) {
      location.href = `/${route}`;
    },
    changeImage(imgCategory) {
      this.imageUrls[
        imgCategory
      ].default = `${this.imageUrls[imgCategory].default}_active`;
    },
    returnImage(imgCategory) {
      this.imageUrls[imgCategory].default = this.imageUrls[
        imgCategory
      ].default.replace("_active", "");
    },

    clickVideoStreamingHandler(user) {
      location.href = `/live-streaming/${user.id}`;
    },

    async updatePlaceStreamingInfo() {
      const obj = {
        place_heading: this.user.place_heading,
        place_description: this.user.place_description,
        place_avatar_full_path: this.user.place_avatar_full_path,
        thumbnail_path: this.user.thumbnail_video_path,
        thumbnail_video_path: this.user.thumbnail_video_path,
        thumbnail_image_path: this.user.thumbnail_image_path,
        should_record: this.user.should_record,
        datetimes:this.user.datetimes
      };
      await api.user.update(this.user.id, obj);
      this.showSnackbar = true;
    },
    async updateViewerStatus(status) {
      if (status) {
        await api.user.addViewer(this.streamingUser.id, this.user.id);
      } else {
        await api.user.removeViewer(this.streamingUser.id, this.user.id);
      }
    },
    async finishStreaming() {
      await api.user.updateVideoStreamingDetails(this.user.id, {
        is_streaming: false
      });
    },
    async getAllViewers() {
      const res = await api.user.getAllViewers(this.user.id, {
        params: {
          category: "place"
        }
      });
      this.viewers = res.data;
    },
    async getVideoStreamingUsers() {
      const res = await api.user.getVideoStreamingUsers({
        params: {
          category: "place"
        }
      });
      this.videoStreamingUsers = res.data;
    },
    goToDashboard() {
        location.href = "/dashboard";
        },
    resetUserType() {
      this.isStreamer = false;
      this.isViewer = false;
      this.showMap = false;
      this.showStreamingUsersList = false;
    },

    imageClickHandler() {
      this.$refs["file-uploader"].click();
    },
    onFileChange(e) {
      this.$set(this.user, "place_avatar_full_path", "");
      let files = e.target.files || e.dataTransfer.files;
      this.passedData = files[0].name;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      this.isLoading.upload = true;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        let src = e.target.result;
        this.onLoad({
          src,
          file
        });
      };
    },
    async onLoad(data) {
      try {
        // this.$refs.errors.error = "";
        let formData = new FormData();
        formData.append("avatar", data.file);
        this.user.place_avatar_full_path = "";
        const res = await api.user.uploadPlaceAvatar(this.user.id, formData);
        this.user.place_avatar_full_path = res.data.place_avatar_full_path;
        this.isLoading.upload = false;
      } catch (err) {
        console.log(err.message);
      }
    },

  }
};
</script>
<style>
        * {box-sizing: border-box;
          padding: 0;
        margin: 0;
      }


body{
    font-family: "Open Sans", sans-serif;
}


    .div-container.app-header {
        background: #2e2e2e;
        height: auto;
        padding: 5px;
        box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
      }
      .div-col12 {
  width: 100%;
}
.app-body-container.appbody-content {
  padding: 15px 0;
}
.div-row {
  flex-wrap: wrap;
  display: flex;
}
      .body-cont {
  max-width: 700px;
  margin: 0 auto;
}
.logo-sec {
  width: 60px;
  float: left;
}
.app-logo {
  width: 100%;
}

ul{
  padding: 0;
  margin: 0;

}
.headlogo-li{
  list-style: none;
    float: left;
    width: 33.3%;
}
.video-icon-sec {
  width: 50px;
margin: 5px auto;
}
.profile-img-sec {
  width: 60px;
  border-radius: 100%;
  float: right;
  text-align: center;
  height: 60px;
  padding-top: 6px;
}
.net-profile-img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 50px;
  box-shadow: 0px 6px 12px 0px rgba(0,0,164,0.29);
  z-index: 1;
  top: 70px;
  right: 0;
  text-align: center;
}

.dropdown-content a {
  color: #3C4043;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  text-align: center;
}

.dropdown-content a:hover {background-color: #ddd; color:#0000A4;}

.dropdown:hover .dropdown-content {display: block;}

.profile-menu i{
  font-size: 16px;

}
.app-profl-sec{
    position: relative;
  background: #BBB6B6;
  border-radius: 20px;
  box-shadow: 0px 0px 14px -6px rgba(0,0,164,1);
}


.app-body-contli {
width: 44%;
list-style: none;
margin: 10px;
display: inline-block;
}
.profile-cont-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  min-height: 200px;
  border-radius: 20px;

}
.text-block {
  position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.9);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 1;
  color: #3C4043;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profname {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.app-body-ul{
  margin: 0 auto;
}
.profname span{
  width:33.3%;
  float: left;
  font-size: 16px;
  padding: 0;
margin: 0;
}
.profclock-span {
  text-align: center;
    padding: 0;
margin: 0;
}
.profstar-span {
  text-align: right;
  padding: 0;
margin: 0;
}
.profstar-span:hover {
  color: #E4AC1A;
}
/*.search-sec {

  border-radius: 100px;
  border: 1px solid #A6A6A6;

  padding: 5px;
}*/
.search-sec input{
  border: none;
  width: 75%;
  border-radius: 100px;
  padding: 10px 15px;
}
.app-bottom-col {
  display: flex;
}

.filt-view {
  text-align: right;
  text-align: center;
}
.thumbview-span {
  width: 50%;
display: inline-block;
text-align: center;
padding: 0 8px;
border-top-left-radius: 30px;
border-bottom-left-radius: 30px;
}
.mapview-span{
  width: 50%;
display: inline-block;
text-align: center;
padding: 10px 8px;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;


}
.filt-view {
  padding: 0;
  margin: 0;
border: 1px solid #ced4da;
border-radius: 30px;

}
.filt-view button{
  background: #fff;
  border: none;
}
.app-bottom-container.app-bottom {
  background: #fff;
  width: 100%;
height: auto;
padding: 10px;
display: flex;
position: fixed;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.appbody-profcont{
  width: 100%;
}
.appbody-profcont tr td{
  padding: 10px;
}
.prof-namcont tr td{
  padding: 10px 5px;
  width: 33%;
}
.prof-namcont{
  width: 100%;
}
.botbar-table{
  width: 100%;
}
.botbar-table tr td:nth-child(1){
  width: 65%;
}


.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-control.input-form {
  padding: 15px 15px;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
    margin-bottom: 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-icon {
  background: transparent;
  color: #888;
  font-size: 24px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.app-bottom-row {
  width: 100%;
}

table {
  border-collapse: collapse;
}
.form-control:focus-visible{
  border: none;
  outline: none;
  box-shadow: 0;
}

.search-sec .input-group {
  border: 1px solid #ced4da;
  border-radius: 50px;
}
.input-group-text.input-icon {
  border: none;
  background: transparent;
}



.mainprof-sec .input-group {
  border: 2px solid #ced4da;


}


.cover-photosec {
  display: block;
  width: 100%;
  height: 300px;
  border: 2px solid #ced4da;

  margin-top: 15px;

}
.mainprof-botbar-table {
  width: 100%;
}
.bot-livbtn {
width: 60px;
display: block;
border: none;
border-radius: 100%;
height: 60px;

margin: 0 auto;
text-align: center;
box-shadow: 0px 0px 16px -8px rgba(0,0,164,0.75);
color: #949497;
font-weight: 500;
}
.bot-livbtn .strambt-icon {
  width: 100%;
}
.mainprof-botbar-table tr td {
  width: 20%;
  text-align: center;
}
.bot-icon i{
  font-size: 18px;
  color: #949497;
}
.bot-icon {
  display: block;
  width: 45px;
  margin: 0 auto;
  height: 45px;
  border-radius: 100%;
  line-height: 30px;
  background: #DFDFDF;
}
.bot-icon img{
  width: 100%;
}
.bot-icon.active{
  background: #0000A4;

}
.bot-icon.active i{
  color: #fff !important;
}

.mainprof-sec .input-group {
  border: 2px solid #ced4da;
}
.form-control.input-form {
  border: none;
}
.mart15{
  margin-top: 15px;
}
.app-body-container.cratstrbody-content{
  padding: 15px;
}
.input-group.mainprof-input {
  border: 2px solid #ced4da;
}



.app-body-container.livebody-content {
  height: 90vh;
}
.live-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  height: 90vh;
}
.livestream-body{
  height: 90vh;
  position: relative;
  z-index: 0;
}
.goliv-txt {
  font-size: 16px;
  padding: 20px 0 10px;
  color: #000;
}





/*Setting page style Start*/
.setingcont-table{
  width: 100%;
}
.setingcont-table tr td{
  text-align: center;
  width: 50%;
  padding: 0;
}
.setting-tab {
  border: 2px solid #1E1EA0;
  border-radius: 50px;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.setingcont-table tr td a{
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: #1E1EA0;
}
.tab-heading-active{
  background: #1E1EA0;
  color: #fff !important;

}
.priterm-cont {
  border: 2px solid #000;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  max-height: 63vh;
  margin-bottom:30px;
}
.setting-pgheading {
  padding: 0;
  margin: 0;
  font-size: 20px;
  margin-bottom: 15px;
}
.priterm-txt {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.privacy-tab{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.terms-tab{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.app-body-setingcont {
  padding: 10px;
}

/*Setting page style Start*/




/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 25px 10px;
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.prfmdlclose {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

.prfmdlclose:hover,
.prfmdlclose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}



.modal-body.profile-modalbody {
  text-align: center;
}
.modal-body.profile-modalbody .profl-img {
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #000;
}

.profl-contbox {
  padding: 15px;
  text-align: left;
}

.modal-body.profile-modalbody .profl-name {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
}
.modal-body.profile-modalbody .profl-descrip {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10px;
}
.modal-body.profile-modalbody .datetme-txt {
  font-size: 16px;
  font-weight: 600;
}





button{
  border: none;
  outline: none;
}
.postliv-table button{
  background: transparent;
}
.postliv-table button img{
  width: 60px !important;
  margin-top: 4px;
  max-height: 40px !important;
  object-fit: cover;
}
.postliv-table {
  width: 130px;
  margin: 0 auto;
  padding: 5px !important;
  background: #1E1EA0;
  border-radius: 40px;
  box-shadow: 0 0 16px -8px rgba(0,0,164,.75);
}
.postliv-table .strm-btn.post-btn img{
  border-right: 1px solid #fff;
}
/*.postliv-table .strm-btn.live-btn img{
  border-left: 2px solid #fff;
}*/




.conttxt{
  font-size: 14px;
  color: #000;
  padding: 7px;
}
.conttxt-heading {
  font-size: 16px;
  padding: 5px 0;
}
.conttxt-ul {
  margin-left: 30px;
}
.conttxt-mainheading {
  font-size: 18px;
  padding: 10px 0;
}

.conttxt-ul li{
  font-size: 14px;
  color: #000;
}

.appmenu-title {
  font-size: 16px;
  padding: 0 10px 10px 10px;
}
.profnamtxt {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}
.profnamicon i{
   font-size: 18px;
  text-align: right;
}
.profnamicon {
  text-align: right;
}
.text-block .posttxt {
  font-size: 12px;
  padding-top: 5px;
}
.livtxt{
    text-align:left;
}

.profl-descspan {
  font-weight: 700;
}
.datetimeberg{
  color-scheme: dark;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="datetime-local"]::-webkit-clear-button {
  font-size: 18px;
  height: 30px;
  color:red;
  background:yellow;
  position: relative;
}

















</style>